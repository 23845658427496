<template>
  <svg
    width="1600px"
    height="900px"
    viewBox="0 0 1600 900"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>path-caesars-forum</title>
    <defs>
      <filter
        x="-5.9%"
        y="-3.6%"
        width="111.9%"
        height="107.2%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="3.00000012"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feMorphology
          radius="3.00000012"
          operator="erode"
          in="SourceAlpha"
          result="shadowInner"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowInner"
          result="shadowInner"
        ></feOffset>
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        ></feComposite>
        <feGaussianBlur
          stdDeviation="5.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
      <radialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="50%"
        gradientTransform="translate(0.5, 0.5), rotate(90), scale(1, 0.9806), translate(-0.5, -0.5)"
        id="radialGradient-3"
      >
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
        <stop
          stop-color="#FFFFFF"
          stop-opacity="0.466182255"
          offset="100%"
        ></stop>
      </radialGradient>
      <path
        d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0"
        id="path-4"
      ></path>
      <filter
        x="-101.6%"
        y="-70.7%"
        width="303.1%"
        height="248.3%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="5.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Casinos"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="path-caesars-forum">
        <g id="Route" stroke-linecap="round" stroke-linejoin="round">
          <path
            class="path-glow"
            fill="none"
            stroke="#ffffff"
            fill-opacity="1"
            filter="url(#filter-2)"
            d="M638.200005,775 L634.84375,775 C634.84375,771.899089 634.84375,768.235352 634.84375,764.008789 C634.84375,759.782227 636.056922,757.771484 638.483267,757.976563 L698.871094,757.976563 L726.321289,752.821289 C760.846029,753.951497 783.619466,754.516602 794.641602,754.516602 C805.663737,754.516602 819.495056,754.516602 836.135559,754.516602 C847.090637,754.773519 852.568176,746.391479 852.568176,729.370483 C852.568176,712.349487 852.568176,701.969035 852.568176,698.229126 C852.429626,687.007935 859.497457,681.397339 873.771667,681.397339 C888.045878,681.397339 911.460714,681.397339 944.016174,681.397339 C956.858663,681.586222 963.279907,673.33493 963.279907,656.643463 C963.279907,639.951996 963.279907,504.360799 963.279907,249.869873 L930.200005,232.600001"
          ></path>
          <path
            stroke="#FFFFFF"
            stroke-width="6.00000024"
            class="path-line"
            fill="none"
            d="M638.200005,775 L634.84375,775 C634.84375,771.899089 634.84375,768.235352 634.84375,764.008789 C634.84375,759.782227 636.056922,757.771484 638.483267,757.976563 L698.871094,757.976563 L726.321289,752.821289 C760.846029,753.951497 783.619466,754.516602 794.641602,754.516602 C805.663737,754.516602 819.495056,754.516602 836.135559,754.516602 C847.090637,754.773519 852.568176,746.391479 852.568176,729.370483 C852.568176,712.349487 852.568176,701.969035 852.568176,698.229126 C852.429626,687.007935 859.497457,681.397339 873.771667,681.397339 C888.045878,681.397339 911.460714,681.397339 944.016174,681.397339 C956.858663,681.586222 963.279907,673.33493 963.279907,656.643463 C963.279907,639.951996 963.279907,504.360799 963.279907,249.869873 L930.200005,232.600001"
          ></path>
        </g>
        <g id="Ending-Point" transform="translate(623.8, 760)">
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="14.6000011"
            r="14.4000006"
          ></circle>
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="14.6000011"
            r="9.60000038"
          ></circle>
          <circle
            id="Oval"
            fill="#5A5A5A"
            cx="14.4000006"
            cy="14.6000011"
            r="4.80000019"
          ></circle>
        </g>
        <image
          id="Bitmap"
          x="498"
          y="710"
          width="157"
          height="141"
          href="@/assets/images/icons/end.png"
        ></image>
        <g id="Starting-Point" transform="translate(915.8, 205)">
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="27.6000011"
            r="14.4000006"
          ></circle>
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="27.6000011"
            r="9.60000038"
          ></circle>
          <circle
            id="Oval"
            fill="#5A5A5A"
            cx="14.4000006"
            cy="27.6000011"
            r="4.80000019"
          ></circle>
          <g id="Fill-1">
            <use
              fill="black"
              fill-opacity="1"
              filter="url(#filter-5)"
              xlink:href="#path-4"
            ></use>
            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4"></use>
          </g>
        </g>
        <text
          id="Caesars-Forum"
          font-family="ArtegraSans-Regular, Artegra Sans"
          font-size="14.4000006"
          font-weight="normal"
          line-spacing="15.6000006"
          fill="#FFFFFF"
        >
          <tspan x="958" y="237">Caesars Forum</tspan>
        </text>
        <rect
          id="Rectangle"
          fill="#000000"
          x="1599"
          y="899"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="0"
          y="0"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="1599"
          y="0"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="0"
          y="899"
          width="1"
          height="1"
        ></rect>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CaesarsForumPath",
  mounted() {
    var path = document.querySelector(".path-line");
    var length = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDasharray = length + " " + length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    path.style.strokeDashoffset = "0";

    var pathGlow = document.querySelector(".path-glow");
    var lengthGlow = pathGlow.getTotalLength();
    pathGlow.style.transition = pathGlow.style.WebkitTransition = "none";
    pathGlow.style.strokeDasharray = lengthGlow + " " + lengthGlow;
    pathGlow.style.strokeDashoffset = lengthGlow;
    pathGlow.getBoundingClientRect();
    pathGlow.style.transition = pathGlow.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    pathGlow.style.strokeDashoffset = "0";
  },
};
</script>

<style lang="scss" scoped>
</style>